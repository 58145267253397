import { createSlice, nanoid } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { FlyToInterpolator } from "react-map-gl";
import { DeckglTypes } from "./data";
import getLatLon from "../functions/getLatLon";

const { latitude, longitude } = getLatLon();

const guidanceDatasetsNames = [
  "Domestic Heat Demand",
  "Rooftop PV Potential",
  "Ground Source Heat Pump Potential",
  "Air Source Heat Pump Potential",
  "Insulation Potential",
  "Domestic Insulation Potential",
  "Domestic Air Source Heat Pump Potential",
  "Domestic Ground Source Heat Pump Potential",
  "Domestic Rooftop PV Potential",
];

const initialState: DeckglTypes = {
  mapStyle: {
    id: "streets",
    label: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
    icon: "https://miro.medium.com/max/1200/1*UzmjsSZkY4m7S_nBXMC9AQ.png",
  },
  dataInfo: {},
  categories: [],
  configlayers: {},
  objectInfo: {},
  viewState: {
    longitude,
    latitude,
    zoom: 10.5,
    minZoom: 2,
    maxZoom: 24,
    bearing: 0,
    pitch: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
  },
  filters: {},
  selectedLayers: [],
  tileUrls: {},
  analytics: {
    enabled: false,
    modeName: null,
    mode: null,
    features: [],
    selectedFeature: null,
    selectedFeatureIndex: null,
    deleteFeature: false,
  },
  timeSeries: {
    visible: false,
    datasetId: "",
    fieldId: "",
    playing: false,
    yAxis: null,
    rangeSlider: false,
  },
  uploadedDatasets: [],
  dataInfoVisible: false,
  downloadProgress: {},
  deleteProgress: {},
  dfesdataInfoVisible: false,
  columnMeta: null,
  loading: true,
  uploadTableData: {},
  guidanceDatasetsName: guidanceDatasetsNames,
  guidanceIds: [],
  mapShowCharts: false, // flag for showing map charts on dfes map
  dfesChosenOptions: {}
};

const deckglModel = createSlice({
  name: "deckgl",
  initialState,
  reducers: {
    clearDeckState: () => initialState,
    saveConfigLayers(state, { payload: { layers } }) {
      state.configlayers = {
        ...state.configlayers,
        ...layers,
      };
    },
    editConfigLayers(state, { payload: { layers, id } }) {
      state.configlayers[id] = { ...layers };
    },
    deleteConfigLayer(state, { payload }) {
      delete state.configlayers[payload];
    },
    addObjectInfo(state, { payload }) {
      state.objectInfo = payload;
    },
    addMapStyle(state, { payload }) {
      state.mapStyle = payload;
    },
    changeViewState(state, { payload }) {
      state.viewState = { ...state.viewState, ...payload };
    },
    changeZoomLevel(state, { payload: { direction } }) {
      state.viewState = {
        ...state.viewState,
        transitionDuration: 225,
        ...{
          zoom:
            direction === "plus"
              ? Math.min(state.viewState.zoom + 1.1, state.viewState.maxZoom)
              : Math.max(state.viewState.zoom - 1.1, state.viewState.minZoom),
        },

        // state.viewState: state.viewState.zoom + 1,
      };
    },
    editDataInfo(state, { payload }) {
      state.dataInfo[payload.id] = { ...payload.content };
    },
    deleteDataInfo(state, { payload }) {
      delete state.dataInfo[payload];
    },
    addFilter(state, { payload: { id, name } }) {
      state.filters[id] = [
        {
          fieldId: name ? name : nanoid(4),
          data: {
            fieldBasedOn: "",
            values: [],
            range: [],
            minmax: [],
            type: "",
            mark: null,
          },
        },
      ];
    },
    setFilter(state, { payload }) {
      state.filters = { ...state.filters, ...payload };
    },
    updateFilters(state, { payload }) {
      state.filters = payload;
    },
    deleteFilter(state, { payload }) {
      delete state.filters[payload];
    },
    addFilterField(state, { payload: { datasetId } }) {
      state.filters[datasetId] = [
        ...state.filters[datasetId],
        {
          fieldId: nanoid(4),
          data: {
            fieldBasedOn: "",
            values: [],
            range: [],
            minmax: [],
            type: "",
            mark: null,
          },
        },
      ];
    },
    deleteFilterField(state, { payload: { datasetId, fieldId } }) {
      state.filters[datasetId] = state.filters[datasetId].filter(
        (item: any) => item.fieldId !== fieldId
      );
    },
    updateFilterFieldData(
      state,
      {
        payload: {
          datasetId,
          fieldId,
          value,
          range,
          minmax,
          values,
          type,
          mark,
        },
      }
    ) {
      state.filters[datasetId] = state.filters[datasetId].map((field: any) =>
        field.fieldId === fieldId
          ? {
              fieldId: fieldId,
              data: {
                fieldBasedOn: value,
                values: values,
                range: range,
                minmax: minmax,
                type,
                mark,
              },
            }
          : field
      );
    },
    updateSelectedLayers(state, { payload: { data, type } }) {
      if (type === "click") {
        state.selectedLayers = data;
      } else if (type === "upload") {
        state.selectedLayers.push(data);
      } else {
        state.selectedLayers = Array.from(
          new Set([...state.selectedLayers, data])
        );
      }
    },
    editAnalytics(state, { payload }) {
      state.analytics = { ...state.analytics, ...payload };
    },
    setTimeSeries(state, { payload }) {
      state.timeSeries = payload;
    },
    updateTimeSeries(state, { payload }) {
      state.timeSeries = {
        ...state.timeSeries,
        ...payload,
      };
    },
    updateUploadedDatasets(state, { payload }) {
      state.uploadedDatasets = payload;
    },
    updateUploadedDatasetsProgress(state, { payload }) {
      const { id, download_status } = payload;
      const index = state.uploadedDatasets.findIndex(
        (item: any) => item.dataset_id === id
      );
      state.uploadedDatasets[index].download_status = download_status;
    },
    setDataInfoVisible(state, { payload }) {
      state.dataInfoVisible = payload;
    },
    updateDownloadProgress(state, { payload: { id, percentage, type } }) {
      if (type === "add") {
        state.downloadProgress[id] = percentage;
      } else {
        delete state.downloadProgress[id];
      }
    },
    deleteDataset(state, { payload }) {
      delete state.dataInfo[payload];
      delete state.configlayers[payload];
      if (!isEmpty(state.filters[payload])) {
        delete state.filters[payload];
      }
      const newSelectedLayer = state.selectedLayers.filter(
        (item) => item !== payload
      );
      state.selectedLayers = newSelectedLayer;
    },
    deleteDatasetInfo(state, { payload }) {
      delete state.configlayers[payload];
      if (!isEmpty(state.filters[payload])) {
        delete state.filters[payload];
      }
      const newSelectedLayer = state.selectedLayers.filter(
        (item) => item !== payload
      );
      state.selectedLayers = newSelectedLayer;
    },
    updateDeleteProgress(state, { payload: { id, percentage, type } }) {
      if (type === "add") {
        state.deleteProgress[id] = percentage;
      } else {
        delete state.deleteProgress[id];
      }
    },
    addCategories(state, { payload }) {
      state.categories = payload;
    },
    updateTileUrls(state, { payload }) {
      const { id, data } = payload;
      state.tileUrls[id] = data;
    },
    setDfesDataInfoVisible(state, { payload }) {
      state.dfesdataInfoVisible = payload;
    },
    setColumnMeta(state, { payload: { columns } }) {
      state.columnMeta = {
        ...state.columnMeta,
        ...columns,
      };
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setUploadTableData(state, { payload: { tableData } }) {
      state.uploadTableData = {
        ...state.uploadTableData,
        ...tableData,
      };
    },
    setShowMapCharts(state, { payload }) {
      state.mapShowCharts = payload;
    },
    setDfesChosenOptions(state, { payload }) {
      state.dfesChosenOptions = payload;
    },
  },
});

export const {
  clearDeckState,
  saveConfigLayers,
  editConfigLayers,
  deleteConfigLayer,
  addObjectInfo,
  addMapStyle,
  changeViewState,
  changeZoomLevel,
  editDataInfo,
  deleteDataInfo,
  addFilter,
  setFilter,
  updateFilters,
  deleteFilter,
  addFilterField,
  deleteFilterField,
  updateFilterFieldData,
  updateSelectedLayers,
  editAnalytics,
  setTimeSeries,
  updateTimeSeries,
  setDataInfoVisible,
  updateDownloadProgress,
  deleteDataset,
  updateDeleteProgress,
  addCategories,
  updateTileUrls,
  setDfesDataInfoVisible,
  updateUploadedDatasets,
  updateUploadedDatasetsProgress,
  deleteDatasetInfo,
  setColumnMeta,
  setLoading,
  setUploadTableData,
  setShowMapCharts,
  setDfesChosenOptions,
} = deckglModel.actions;
export default deckglModel.reducer;
