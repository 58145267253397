import { createSlice } from "@reduxjs/toolkit";
import { globalType } from "./data";

const intercomIds = {
  productTourIds: {
    UKPN_TOUR_ID_SCENARIO: 7899,
    SSEN_TOUR_ID_SCENARIO: 7928,
    UKPN_TOUR_ID_STAKEHOLDER: 7898,
    SSEN_TOUR_ID_STAKEHOLDER: 7629,
    UKPN_TOUR_ID_DATA_STORY: 7903,
    SSEN_TOUR_ID_DATA_STORY: 7930,
    YLNZH_TOUR_ID: 7814,
    LENZA_TOUR_ID: 7926,
    LAEP_TOUR_ID: 7927,
    NEW_USER_HOMEPAGE_TOUR_ID: 7978,
  },
  articleIds: {
    DOMESTIC_HEAT_DEMAND: 141544,
    ROOFTOP_PV_POTENTIAL: 141542,
    DOMESTIC_ROOFTOP_PV_POTENTIAL: 141542,
    GROUND_SOURCE_HEAT_PUMP_POTENTIAL: 141541,
    AIR_SOURCE_HEAT_PUMP_POTENTIAL: 141540,
    INSULATION_POTENTIAL: 141539,
    STAKEHOLDER_UKPN: 141588,
    STAKEHOLDER_SSEN: 141545,
    STAKEHOLDER_LAEP: 141508,
    DATA_STORY_UKPN: 141591,
    DATA_STORY_SSEN: 141552,
    DATA_STORY_LAEP: 141509,
    DOMESTIC_INSULATION_POTENTIAL: 141539,
    DOMESTIC_AIR_SOURCE_HEAT_PUMP_POTENTIAL: 141540,
    DOMESTIC_GROUND_SOURCE_HEAT_PUMP_POTENTIAL: 141541,
    PLANNING_STATUS_UKPN: 141503,
    PLANNING_STATUS_SSEN: 141502,
    PLANNING_STATUS_LAEP: 141501,
    SCENARIOS_WHAT_DOES_THIS_MEAN_UKPN: 141583,
    SCENARIOS_WHAT_DOES_THIS_MEAN_SSEN: 141565,
    SCENARIOS_WHAT_DOES_THIS_MEAN_LAEP: 141507,
    UPLOAD_REQUIREMENTS_UKPN: 141553,
    UPLOAD_REQUIREMENTS_SSEN: 141528,
    UPLOAD_REQUIREMENTS_LAEP: 141596,
    PROJECTS_VIEW_FULL_GUIDANCE_UKPN: 141581,
    PROJECTS_VIEW_FULL_GUIDANCE_SSEN: 141568,
    PROJECTS_VIEW_FULL_GUIDANCE_LAEP: 141513,
    PROJECT_HOW_IS_THIS_ESTIMATED_UKPN: 141538,
    PROJECT_HOW_IS_THIS_ESTIMATED_SSEN: 141523,
    PROJECT_HOW_IS_THIS_ESTIMATED_LAEP: 141524,
    PROJECT_BUDGET_ESTIMATE_UKPN: 141505,
    PROJECT_BUDGET_ESTIMATE_SSEN: 141505,
    PROJECT_BUDGET_ESTIMATE_LAEP: 141505,
    MANAGE_TEMPLATE_UKPN: 143019,
    MANAGE_TEMPLATE_SSEN: 143021,
    MANAGE_TEMPLATE_LAEP: 143023
  },
};

const initialState: globalType = {
  activePage: "Map",
  pageIndex: 1,
  error: {
    status: 0,
    message: "",
  },
  mainSidebarVisible: false,
  openedMenu: [],
  laModalVisible: false,
  intercomIds: intercomIds,
};

const globalModel = createSlice({
  name: "global",
  initialState,
  reducers: {
    queryActivePage(state, { payload: { pageName, index } }) {
      state.activePage = pageName;
      state.pageIndex = index;
    },
    raiseError(state, { payload }) {
      state.error.status = payload.status;
      state.error.message = payload.message;
    },
    setMainSidebarVisible(state, { payload }) {
      state.mainSidebarVisible = payload;
    },
    setOpenedMenu(state, { payload }) {
      state.openedMenu = payload;
    },
    setLaModalVisible(state, { payload }) {
      state.laModalVisible = payload;
    },
  },
});

export const {
  queryActivePage,
  raiseError,
  setMainSidebarVisible,
  setOpenedMenu,
  setLaModalVisible,
} = globalModel.actions;
export default globalModel.reducer;
