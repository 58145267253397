import { Avatar, Button, Menu, Layout, Space, Typography, Modal } from "antd";
//@ts-ignore
import { useHistory, useLocation } from "react-router-dom";
import {
  LogoutOutlined,
  UserOutlined,
  InfoCircleFilled,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  clearDeckState,
  clearUser,
  queryActivePage,
  setLaModalVisible,
  setMainSidebarVisible,
  setOpenedMenu,
} from "../../model";
import { StateTypes } from "../../model/data";
import { useQueryClient } from "react-query";
import ukpn_icon from "../../assets/UK-Power-Networks-Logo 2.png";
import icon from "../../assets/new_logo_ukpn.svg";
import useProjectStore, { ProjectStateTypes } from "../../stores/ProjectStore";
import ai_logo from "../../assets/ai_logo_white.png";
import ai_logo_icon from "../../assets/ai_white_icon.png";
import ssen_logo_default from "../../assets/ssen_new_updated_white_logo.png";
import ssen_icon_default from "../../assets/updatedLogo.png";
import ai_long_logo from "../../assets/ai_long_logo.png";
import ai_short_logo from "../../assets/ai_short_logo.png";
import esc_logo from "../../assets/AIESC_Logo_Horizontal_PNG.svg";
import esc_short_logo from "../../assets/AIESC_Logo_Vertical_PNG.png";
import { isEmpty } from "lodash";
import SelectLa from "./SelectLa";
import { useEffect, useState } from "react";
import useFeatureFlags from "../../hooks/useFeatureFlags";
import { setIsTermAndConditionVisible } from "../../model/dashboard";
import { LAEP_SSEN_ICONS, UKPN_ICONS } from "../../constants/appIcons";
import useAxios from "../../hooks/useAxios";
import { useLogoutMutation } from "../../global/globalApi";

// const DATASET_METHODOLOGIES_URL =
//   "https://leouserfile.s3.eu-central-1.amazonaws.com/Energeo_OCC_Project-LEO_Report_V3.pdf";

// const USER_GUIDELINES_URL =
//   "https://leouserfile.s3.eu-central-1.amazonaws.com/User+Guidelines+V1.4.pdf";

const { Sider } = Layout;

const dno_name = `${process.env.REACT_APP_DNO_NAME}`;

const MainMenu = () => {
  const [scrollIndicate, setScrollIndicate] = useState<boolean>(false);
  const [iconsList, setIconsList] = useState<any>({});
  const [menuItems, setMenuItems] = useState<any>([]);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const isAdmin = useSelector((state: StateTypes) => state.user.isAdmin);
  const isDsoUser = useSelector((state: StateTypes) => state.user.isDsoUser);

  const la = JSON.parse(
    window.sessionStorage.getItem("local_authority") ?? "{}"
  );

  const collapsed = useSelector(
    (state: StateTypes) => state.global.mainSidebarVisible
  );
  const { openedMenu } = useSelector((state: StateTypes) => state.global);

  const termsAccepted = useSelector(
    (state: StateTypes) => state.dashboard.termsAccepted
  );

  const access_token = useSelector(
    (state: StateTypes) => state.user.currentUser
  );

  const resetProjects = useProjectStore(
    (s: ProjectStateTypes) => s.resetProjects
  );
  const { defaultMapId, currentMapInfo } = useSelector(
    (state: StateTypes) => state.displayEntity
  );
  const [betaModalOpen, setBetaModalOpen] = useState(false);
  const dispatch = useDispatch();
  const features = useFeatureFlags();
  const history = useHistory();
  const queryClient = useQueryClient();
  const axios = useAxios();

  const { mutate } = useLogoutMutation(axios);

  const signout = async () => {
    const payload = {
      access_token: access_token?.accessToken ? access_token?.accessToken : "",
    };
    mutate(payload, {
      onSuccess: () => {
        batch(() => {
          dispatch(clearUser());
          dispatch(clearDeckState());
          dispatch(queryActivePage({ pageName: "/", index: 0 }));
        });
        window.Intercom("shutdown");
        resetProjects();
        window.sessionStorage.removeItem("currentUser");
        window.sessionStorage.removeItem("local_authority");
        window.sessionStorage.removeItem("previousPageUrl")
        queryClient.removeQueries(["datasets"]);
        history.push("/");
      },
    });
  };

  useEffect(() => {
    if (la.name) {
      setMenuItems(menuTopItems);
    } else {
      setMenuItems([
        {
          key: 0,
          label: "Home",
          icon: iconsList["Home"],
          path: `/la/${la?.name}/home`,
          id: "Home",
          enabled: true,
        },
        {
          key: 9,
          label: "DSO Dashboard",
          icon: iconsList["Dso-dashboard"],
          id: "Dso-dashboard",
          path: "/dso-dashboard",
          type: "submenu",
          enabled: features?.dso && isDsoUser,
          children: [
            {
              key: 91,
              label: "Dashboard",
              path: "/dso-dashboard/dashboard",
              id: "Dso-submenu-dashboard",
              enabled: true,
            },
            {
              key: 92,
              label: "Local Authority Groups",
              path: "/dso-dashboard/la-groups",
              id: "Dso-la-groups",
              enabled: true,
            },
            {
              key: 93,
              label: "Shared Data",
              path: "/dso-dashboard/shared-data",
              id: "Dso-shared-data",
              enabled: features?.["dso-shared-data"],
            },
          ].filter((item) => item.enabled),
        },
      ]);
    }
    // eslint-disable-next-line
  }, [la.name, iconsList]);

  const handleNavigation = (key: string) => {
    let item: any = menuTopItems.find((item) => item.key === parseInt(key));
    if (isEmpty(item)) {
      const itemIndex = menuTopItems.findIndex(
        (item) => item.key === parseInt(key?.slice(0, 1))
      );
      item = menuTopItems[itemIndex].children?.find(
        (elm) => elm.key === parseInt(key)
      );
    }

    dispatch(queryActivePage({ pageName: item.label, index: item.key }));
    history.push(`${item.path}`);
  };

  const handleHomePageRoute = (key: string) => {
    if (key === "0" && params.pathname.includes("data-stories")) {
      history.push(`/la/${la.name}/home`);
    }

    if (key === "8" && params.pathname.includes("scenarios")) {
      history.push(`/la/${la.name}/scenarios`);
    }
  };

  const menuTopItems = [
    {
      key: 0,
      label: "Home",
      icon: iconsList["Home"],
      path: `/la/${la?.name}/home`,
      id: "Home",
      enabled: true,
    },
    {
      key: 1,
      label: "Map",
      icon: iconsList["Map"],
      type: "submenu",
      path: currentMapInfo
        ? `/la/${la.name}/map/${currentMapInfo.map_id}`
        : `/la/${la.name}/map/${defaultMapId}`,
      id: "Map",
      enabled: features?.map,
      children: [
        {
          key: 11,
          label: "Data",
          path: currentMapInfo
            ? `/la/${la.name}/map/${currentMapInfo.map_id}?type=data`
            : `/la/${la.name}/map/${defaultMapId}?type=data`,
          id: "Data",
          enabled: features?.map,
        },
        {
          key: 12,
          label: "DFES",
          path: currentMapInfo
            ? `/la/${la.name}/map/${currentMapInfo.map_id}?type=dfes`
            : `/la/${la.name}/map/${defaultMapId}?type=dfes`,
          id: "Dfes",
          enabled: features?.dfes,
        },
        {
          key: 13,
          label: "Projects",
          path: currentMapInfo
            ? `/la/${la.name}/map/${currentMapInfo.map_id}?type=projects`
            : `/la/${la.name}/map/${defaultMapId}?type=projects`,
          id: "Projects",
          enabled: features?.projects,
        },
      ].filter((item) => item.enabled),
    },
    {
      key: 2,
      label: "Resources",
      icon: iconsList["Resources"],
      className: "resources-tab",
      path: `/la/${la.name}/resources`,
      id: "Resources",
      type: "submenu",
      enabled: features?.["off-map-guidance"],
      children: [
        {
          key: 21,
          label: "Guidance",
          path: `/la/${la.name}/resources?tab=guidance`,
          id: "Guidance",
          enabled: features?.guidance,
        },
        {
          key: 22,
          label: "Use cases",
          path: `/la/${la.name}/resources?tab=use-cases`,
          id: "Use_cases",
          enabled: features?.["use-cases"],
        },
        {
          key: 23,
          label: "News and events",
          path: `/la/${la.name}/resources?tab=news-events`,
          id: "News_events",
          enabled: features?.["news-and-events"],
        },
        {
          key: 24,
          label: "Funding Sources",
          path: `/la/${la.name}/resources?tab=funding-sources`,
          id: "Funding_resources",
          enabled: features?.["funding-sources"],
        },
      ].filter((item) => item.enabled),
    },
    {
      key: 10,
      label: "Our Forecasts & Your Plans",
      icon: iconsList["PlansAndForecasts"],
      path: `/la/${la.name}/shareplans`,
      id: "Shareplans",
      enabled: features?.["our-forecasts-and-your-plans"],
    },
    {
      key: 3,
      label: "Dashboard",
      icon: iconsList["Dashboard"],
      path: `/la/${la.name}/dashboard`,
      id: "Dashboard",
      enabled: features?.dashboards,
    },
    {
      key: 15,
      label: "Planning Progress",
      icon: iconsList["planningProgress"],
      path: `/la/${la.name}/planning-progress`,
      id: "PlanningProgress",
      enabled: features?.["planning-status"],
    },
    {
      key: 6,
      label: "Progress Tracker",
      icon: iconsList["ProgressTracker"],
      path: `/la/${la.name}/progress-tracker`,
      id: "ProgressTracker",
      enabled: features?.["progress-tracker"],
    },
    // {
    //   key: 4,
    //   label: "Analytics",
    //   title: "Analytics",
    //   icon: <SelectOutlined />,
    //   path: "/analytics",
    //   enabled:features?.["dataset-analytics"]
    // },
    {
      key: 5,
      label: "Manage Access",
      icon: iconsList["ManageAccess"],
      path: `/la/${la.name}/access`,
      id: "ManageAccess",
      enabled: features?.["manage-access"] && isAdmin,
    },
    {
      key: 7,
      label: "Stakeholder Engagement",
      icon: iconsList["Stakeholder-engagement"],
      path: `/la/${la.name}/stakeholder-engagement`,
      id: "Stakeholder-engagement",
      enabled: features?.["stakeholder-engagement"],
    },
    {
      key: 17,
      label: "Building Portfolios",
      icon: iconsList["Portfolios"],
      path: `/la/${la.name}/portfolios`,
      id: "portfolios",
      enabled: features?.portfolios,
    },
    {
      key: 8,
      label: "Scenarios",
      icon: iconsList["Scenarios"],
      path: `/la/${la.name}/scenarios`,
      id: "Scenarios",
      enabled: features?.scenarios,
    },
    {
      key: 9,
      label: "DSO Dashboard",
      icon: iconsList["Dso-dashboard"],
      id: "Dso-dashboard",
      path: "/dso-dashboard",
      type: "submenu",
      enabled: features?.dso && isDsoUser,
      children: [
        {
          key: 91,
          label: "Dashboard",
          path: "/dso-dashboard/dashboard",
          id: "Dso-submenu-dashboard",
          enabled: features?.["dso-dashboard"],
        },
        {
          key: 92,
          label: "Local Authority Groups",
          path: "/dso-dashboard/la-groups",
          id: "Dso-la-groups",
          enabled: true,
        },
        {
          key: 93,
          label: "Shared Data",
          path: "/dso-dashboard/shared-data",
          id: "Dso-shared-data",
          enabled: features?.["dso-shared-data"],
        },
      ].filter((item) => item.enabled),
    },
    {
      key: 99,
      label: "Master Plan",
      icon: iconsList["masterPlan"],
      path: `/la/${la.name}/masterplan`,

      enabled: features?.["master-plan"],
    },
  ].filter((item) => item.enabled);

  const params = useLocation();

  const currentPage = menuTopItems.find((item: any) =>
    params.pathname.startsWith(item.path)
  )?.key;

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    if (dno_name === "ukpn") {
      setIconsList(UKPN_ICONS);
    } else if (dno_name === "laep") {
      setIconsList(LAEP_SSEN_ICONS);
    } else if (dno_name === "ssen") {
      setIconsList(LAEP_SSEN_ICONS);
    } else {
      setIconsList(UKPN_ICONS);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMove = (direction: any) => {
    const menu = document.getElementById("your-menu-id");
    if (menu) {
      if (direction === "up") {
        menu.scrollTo(menu.scrollTop, menu.scrollTop + 32);
      } else {
        menu.scrollTo(menu.scrollTop, menu.scrollTop - 32);
      }
    }
  };

  useEffect(() => {
    const observeMenuArea = (target: any) => {
      const observer = new IntersectionObserver((entries) => {
        const isIntersecting = entries[0].isIntersecting;
        setScrollIndicate(isIntersecting);
      });
      observer.observe(target);
      return () => {
        observer.unobserve(target);
      };
    };
    const menuArea = document.getElementById(`your-menu-id`) as HTMLElement;
    if (menuArea && menuArea.scrollHeight > menuArea.clientHeight) {
      observeMenuArea(menuArea);
    } else {
      const mutationObserver = new MutationObserver(() => {
        const updatedMenuArea = document.getElementById(
          `your-menu-id`
        ) as HTMLElement;
        if (
          updatedMenuArea &&
          updatedMenuArea.scrollHeight > updatedMenuArea.clientHeight
        ) {
          observeMenuArea(updatedMenuArea);
          mutationObserver.disconnect();
        } else {
          setScrollIndicate(false);
        }
      });

      mutationObserver.observe(document.body, {
        subtree: true,
        childList: true,
      });
    }

    return () => {
      const observedMenuArea = document.getElementById(
        `your-menu-id`
      ) as HTMLElement;
      if (observedMenuArea) {
      }
    };
    // eslint-disable-next-line
  }, [windowHeight]);

  return (
    <Sider
      width={230}
      className="sider"
      trigger={
        <div
          style={{
            position: "relative",
            bottom: 20,
          }}
        >
          <div
            style={{
              position: "relative",
              bottom: -20,
            }}
          >
            <Button
              type="text"
              disabled={!termsAccepted || isEmpty(la)}
              style={{ cursor: "pointer", zIndex: 2 }}
              onClick={() => dispatch(setIsTermAndConditionVisible(true))}
            >
              <span
                style={{
                  color: "white",
                  textDecoration: "underline",
                }}
              >
                {collapsed ? "Terms" : "Terms and conditions"}
              </span>
            </Button>
          </div>
          <div style={{ position: "relative", bottom: 35 }}>
            {/* {dno_name === "ssen" ? (
              collapsed && dno_name === "ssen" ? (
                <div style={{ position: "relative", bottom: 30 }}>
                  <RightOutlined style={{ color: "var(--default-white)" }} />
                </div>
              ) : !collapsed && dno_name === "ssen" ? (
                <div style={{ position: "relative", bottom: 30 }}>
                  <LeftOutlined style={{ color: "var(--default-white)" }} />
                </div>
              ) : null
            ) :  */}
            {collapsed ? (
              <div
                className="main-menu-below-icons"
                style={{ marginTop: dno_name === "laep" ? -10 : 0 }}
              >
                {dno_name !== "ssen" ? (
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: 11,
                      lineHeight: 2,
                      color: "var(--default-white)",
                    }}
                  >
                    Powered by
                  </div>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => dispatch(setMainSidebarVisible(false))}
                >
                  <img
                    src={dno_name === "ssen" ? ai_short_logo : ai_logo_icon}
                    alt="ai_logo"
                    style={{ width: 40, height: 40 }}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  marginTop: -40,
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "5px 0",
                    paddingTop: dno_name === "ssen" ? 5 : 0,
                  }}
                >
                  {dno_name !== "ssen" ? (
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: 14,
                        color: "var(--default-white)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 10,
                      }}
                    >
                      Powered by
                    </div>
                  ) : null}
                  <div onClick={() => dispatch(setMainSidebarVisible(true))}>
                    <img
                      src={dno_name === "ssen" ? ai_long_logo : ai_logo}
                      alt="ai_logo"
                      style={{
                        width: dno_name === "ssen" ? 130 : 90,
                        height: dno_name === "ssen" ? 40 : 30,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      }
      collapsible
      collapsed={collapsed}
    >
      {/* Beta alert, Remove this on final version */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => setBetaModalOpen(true)}
          icon={<InfoCircleFilled />}
          type="primary"
          shape="round"
          size="small"
          style={{
            border: "none",
            background: "white",
            color: "var(--default-cta)",
            fontWeight: 500,
            width: collapsed ? "90%" : "80%",
            marginTop: 12,
          }}
          block
        >
          {collapsed ? `Beta` : `Beta Version`}
        </Button>
      </div>

      <div
        className={collapsed ? "icon" : "logo"}
        style={{ margin: dno_name === "ssen" ? 0 : "0 20px" }}
      >
        {!collapsed ? (
          <div
            style={{
              display: dno_name === "laep" ? "flex" : "block",
              justifyContent: "center",
            }}
          >
            <img
              src={
                dno_name === "ssen"
                  ? ssen_logo_default
                  : dno_name === "ukpn"
                    ? ukpn_icon
                    : dno_name === "esc"
                      ? esc_logo
                      : ai_long_logo
              }
              alt="logo"
              style={{
                width:
                  dno_name === "ssen"
                    ? "100%"
                    : dno_name === "esc"
                      ? 300
                      : "75%",
                padding: dno_name === "ssen" ? "10px 0px" : 0,
                margin:
                  dno_name === "laep"
                    ? "15px 0"
                    : dno_name === "esc"
                      ? "-30px 0 -30px -55px"
                      : 0,
              }}
            />
          </div>
        ) : (
          <div
            style={{
              width:
                dno_name === "esc"
                  ? "-40px -80px"
                  : dno_name === "ssen"
                    ? "auto"
                    : "100%",
              height: dno_name === "esc" ? 100 : 38,
              margin: dno_name === "ssen" ? 20 : dno_name === "laep" ? "20px 0" : 0,
            }}
          >
            <img
              src={
                dno_name === "ssen"
                  ? ssen_icon_default
                  : dno_name === "ukpn"
                    ? icon
                    : dno_name === "esc"
                      ? esc_short_logo
                      : ai_short_logo
              }
              alt="logo"
              style={{
                width:
                  dno_name === "ssen" ? 38 : dno_name === "esc" ? 350 : "100%",
                marginTop: dno_name === "esc" ? -44 : 0,
              }}
            />
          </div>
        )}
      </div>
      {scrollIndicate && (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <UpOutlined
            onClick={() => handleMove("down")}
            style={{ fontSize: "20px", color: "white" }}
          />
        </div>
      )}
      <Menu
        theme="dark"
        mode="vertical"
        items={menuItems}
        selectedKeys={currentPage ? [currentPage.toString()] : ["0"]}
        style={{
          justifyContent: "center",
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
          margin: collapsed ? "0px" : "0 0 0 5px",
          height: "52vh",
          overflowY: "scroll",
        }}
        className="top-menu"
        onOpenChange={(key) =>
          dispatch(setOpenedMenu(isEmpty(openedMenu) ? [key.toString()] : []))
        }
        onSelect={({ key }) => handleNavigation(key)}
        onClick={({ key }) => handleHomePageRoute(key)}
        id="your-menu-id"
      // openKeys={openedMenu}
      />

      {scrollIndicate && (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <DownOutlined
            onClick={() => handleMove("up")}
            style={{ fontSize: "20px", color: "white" }}
          />
        </div>
      )}

      <Space
        className={`bottom-menu ${collapsed ? "collapsed" : ""}`}
        direction="vertical"
        size="small"
        style={{ width: "100%" }}
      >
        {!isEmpty(la.name) ? (
          <Button
            type="text"
            icon={
              <Avatar
                className="main-menu-avatar"
                size="small"
                icon={la?.name ? null : <UserOutlined />}
                style={{
                  backgroundColor: "var(--default-white)",
                  color: "black",
                }}
              >
                {la?.name?.toUpperCase()}
              </Avatar>
            }
            block
            style={{
              backgroundColor: collapsed ? "transparent" : "var(--light-shade)",
              padding: 8,
              height: "auto",
              borderRadius: 4,
              width: collapsed ? "auto" : 175,
              display: "flex",
              alignItems: "center",
              position: "relative",
              bottom: 30,
            }}
            onClick={() => dispatch(setLaModalVisible(true))}
          >
            {collapsed ? null : (
              <Typography.Text
                ellipsis
                style={{
                  color: "white",
                  fontSize: 11,
                  textTransform: "capitalize",
                  width: 120,
                }}
              >
                {la?.description}
              </Typography.Text>
            )}
          </Button>
        ) : null}

        <Button
          icon={<LogoutOutlined />}
          type="text"
          style={{
            background: "transparent",
            border: collapsed ? "none" : "2px solid var(--default-grey)",
            borderRadius: 4,
            height: 40,
            width: collapsed ? "auto" : 175,
            position: "relative",
            bottom: 30,
          }}
          onClick={signout}
          block
        >
          {collapsed ? null : "Logout"}
        </Button>
      </Space>
      <SelectLa />
      <Modal
        title="Beta Version"
        open={betaModalOpen}
        footer={false}
        onCancel={() => setBetaModalOpen(false)}
        width={800}
      >
        <b>What does "beta" mean?</b>
        <br />
        <br />
        In software development, the beta phase is an important step before the
        official release. It allows us to gather feedback, identify potential
        issues, and make improvements based on your feedback.
        <br />
        <br /> It also means that bugs are more common as we iterate and test
        new datasets and features. If you do experience a bug - let us know via
        the Live Chat and we'll get this resolved for you as soon as possible.
        <br />
        <br />
        <b>As a beta user...</b>
        <br />
        <br />
        You have the unique opportunity to explore{" "}
        {process.env.REACT_APP_TOOL_NAME}'s functionality ahead of its official
        launch. By providing us with your feedback, you can help shape its
        development and contribute to making it even better.
        <br />
        <br />
        If you have any enhancement or data requests, feel free to raise these
        in one of our regular drop in sessions or drop us a note via Live Chat
      </Modal>
    </Sider>
  );
};

export default MainMenu;