import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  globalModel,
  userModal,
  dashboardModel,
  deckglModel,
  reportModel,
  accessModel,
  displayEntityModel,
  analyticsModel,
  scenariosModel,
} from "./model";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "user",
    "deckgl",
    "dashboard",
    "reports",
    "access",
    "global",
    "displayEntity",
    "scenarios",
  ],
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_FIREBASE_API_KEY || "ai_tl_secretKey",
      onError: (err) => {
        console.log(err);
      },
    }),
  ],
};

const userPersistConfig = {
  key: "user",
  storage,
  blacklist: ["notification", "data", "uploadData"],
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_FIREBASE_API_KEY || "ai_tl_secretKey",
      onError: (err) => {
        console.log(err);
      },
    }),
  ],
};

const reducers = combineReducers({
  user: persistReducer(userPersistConfig, userModal),
  dashboard: dashboardModel,
  deckgl: deckglModel,
  reports: reportModel,
  global: globalModel,
  access: accessModel,
  displayEntity: displayEntityModel,
  analytics: analyticsModel,
  scenarios: scenariosModel,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }).concat(),
  devTools: process.env.NODE_ENV === "development",
});

export const persistor = persistStore(store);
export default store;
